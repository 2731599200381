<template>
  <!-- BEGIN:Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Header -->
      <iq-card
        class="iq-card iq-card-block iq-card-stretch iq-card-height"
        :style="
          'background: url(' +
            bgImageURL +
            ') no-repeat 0 0; background-size: cover;'
        "
      >
        <b-row>
          <b-col class="align-self-center" style="margin-top: 150px">
            <h2 class="text-white font-weight-bold text-right">
              Selamat Datang
            </h2>
            <h4
              class="w-75 text-white text-right float-right hide-content-769px"
              style="line-height: 1"
            >
              Aplikasi Kerja Sama Universitas Terbuka (AKSARA) merupakan layanan
              informasi dan pendataan kerja sama Universitas Terbuka dengan
              mitra baik dalam maupun luar negeri.
            </h4>
          </b-col>
          <b-col>
            <img
              src="@/assets/images/bgimage/hs-lg2.png"
              class="img-fluid hide-content-426px"
              alt="logo"
              style="width: 550px"
            />
          </b-col>
        </b-row>
      </iq-card>
      <!-- ./END:Header -->
      <!-- BEGIN:Amount of 3 Contents -->
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-handshake"></i>
              </div>
              <div class="text-right">
                <h5><b>Memorandum of Understanding</b></h5>
                <h3>
                  {{
                    listData && listData.jumlahMou ? listData.jumlahMou : '0'
                  }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-file-signature"></i>
              </div>
              <div class="text-right">
                <h5><b>Memorandum of Agreement</b></h5>
                <h3>
                  {{
                    listData && listData.jumlahMoa ? listData.jumlahMoa : '0'
                  }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-bullseye"></i>
              </div>
              <div class="text-right">
                <h5><b>Implementation Arrangement</b></h5>
                <h3>
                  {{ listData && listData.jumlahIa ? listData.jumlahIa : '0' }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Amount of 3 Contents -->
      <!-- BEGIN:MoU Terbanyak -->
      <b-col lg="6">
        <iq-card class="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fas fa-solid fa-star" style="color: gold"></i> 5 Mitra
              dengan MoU terbanyak
            </h4>
          </template>
          <template v-slot:body>
            <div v-for="mou in listDataMouTerbanyak" :key="mou.namaMitra">
              <div class="iq-details mt-4">
                <span class="title text-dark">{{ mou.namaMitra }}</span>
                <div class="percentage float-right text-primary">
                  {{ mou.jumlah }}
                </div>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <Progressbar :value="mou.jumlah" color="primary" />
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:MoU Terbanyak -->
      <!-- BEGIN:MoA Terbanyak -->
      <b-col lg="6">
        <iq-card class="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fas fa-solid fa-star" style="color: gold"></i> 5 Mitra
              dengan MoA terbanyak
            </h4>
          </template>
          <template v-slot:body>
            <div v-for="moa in listDataMoaTerbanyak" :key="moa.namaMitra">
              <div class="iq-details mt-4">
                <span class="title text-dark">{{ moa.namaMitra }}</span>
                <div class="percentage float-right text-primary">
                  {{ moa.jumlah }}
                </div>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <Progressbar :value="moa.jumlah" color="primary" />
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:MoA Terbanyak -->
      <!-- BEGIN:Chart MoU Per Tahun -->
      <b-col lg="12">
        <div class="iq-card">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title">{{ chartsBar.title }}</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <ApexChart
              ref="refMouPertahun"
              :element="chartsBar.type"
              :chartOption="chartsBar.bodyData"
            />
          </div>
        </div>
      </b-col>
      <!-- ./END:Chart MoU Per Tahun -->
      <!-- BEGIN:Chart MoA Per Kategori -->
      <b-col lg="12">
        <div class="iq-card">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title">{{ chartsStackedBar.title }}</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <HighChart
              ref="refMoaKategori"
              :element="chartsStackedBar.type"
              :option="chartsStackedBar.bodyData"
            />
          </div>
        </div>
      </b-col>
      <!-- ./END:Chart MoA Per Kategori -->
      <!-- BEGIN:Footer -->
      <div
        style="align-items: center; margin-bottom: 30px"
        class="d-flex col-md-12"
      >
        <b-col lg="3" />
        <b-col lg="2">
          <div class="text-justify">
            <h5><b>Universitas Terbuka</b></h5>
            <h6>
              Jl. Pd. Cabe Raya, Pd. Cabe Udik, Kec. Pamulang, Kota Tangerang
              Selatan, Banten 15437
            </h6>
          </div>
        </b-col>
        <b-col lg="1" />
        <b-col>
          <div class="text-justify">
            <h5><b>Tetap Terhubung Dengan Kami</b></h5>
            <h2>
              <a href="https://www.ut.ac.id/" target="_blank"
                ><img
                  src="@/assets/images/favicon-hd.png"
                  class="img-fluid"
                  alt="logo"
                  style="width: 40px"/></a
              >&nbsp;
              <a href="https://www.facebook.com/UnivTerbuka/" target="_blank"
                ><i class="fab fa-facebook"></i
              ></a>
              &nbsp;
              <a
                href="https://www.youtube.com/c/universitasterbukatv"
                target="_blank"
                class="link-yt"
                ><i class="fab fa-youtube"></i
              ></a>
            </h2>
          </div>
        </b-col>
        <b-col lg="3" />
      </div>
      <!-- ./END:Footer -->
    </b-row>
  </b-container>
  <!-- ./END:Page Content  -->
</template>
<script>
// import Vue from 'vue'
import axios from 'axios'
import { core } from '@/config/pluginInit'
import ApexChart from '@/components/core/charts/ApexChart'
import HighChart from '@/components/core/charts/HighChart'
import bgImage from '@/assets/images/bgimage/bg1.jpg'

export default {
  name: 'Dashboard4',
  async mounted() {
    core.index()
    await Promise.all([this.getDataGrafik_()])
  },
  components: { ApexChart, HighChart },
  methods: {
    /**
     * Get data grafik
     */
    async getDataGrafik_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/grafikDashboard`,
        method: 'GET'
      })
        .then(res => {
          this.listData = res.data
          this.listDataMouTerbanyak = res.data.mouTerbanyak
          this.listDataMoaTerbanyak = res.data.moaTerbanyak
          /** BEGIN:MoU per tahun */
          this.listDataMouPertahun = res.data.grafikMouPertahun
          this.dataMouPertahun = this.listDataMouPertahun.map(mou => mou.jumlah)
          this.labelMouPertahun = this.listDataMouPertahun.map(mou => mou.label)
          // Memasukkan data
          this.$refs.refMouPertahun.chartOption.series.map(
            a => (a.data = this.dataMouPertahun)
          )
          // Memasukkan label
          for (var il = 0; il < this.labelMouPertahun.length; il++) {
            this.$refs.refMouPertahun.chartOption.xaxis.categories[
              il
            ] = this.labelMouPertahun[il]
          }
          /** ./END:MoU per tahun */
          /** BEGIN:MoA kategori */
          this.listDataMoaKategori = res.data.grafikMoaKategori
          this.dataMoaKategoriAktif = this.listDataMoaKategori.map(
            moa => moa.aktif
          )
          this.dataMoaKategoriTidakAktif = this.listDataMoaKategori.map(
            moa => moa.tidakAktif
          )
          this.labelMoaKategori = this.listDataMoaKategori.map(moa => moa.label)
          // Memasukkan data
          this.$refs.refMoaKategori.option.series[0].data = this.dataMoaKategoriAktif
          this.$refs.refMoaKategori.option.series[1].data = this.dataMoaKategoriTidakAktif
          // Memasukkan label
          for (var jl = 0; jl < this.labelMoaKategori.length; jl++) {
            this.$refs.refMoaKategori.option.xAxis.categories[
              jl
            ] = this.labelMoaKategori[jl]
          }
          /** ./END:MoA kategori */
        })
        .catch(err => {
          console.log('ERROR grafikDashboard:', err)
          throw new Error(err)
        })
      return result
    }
  },
  data() {
    return {
      bgImageURL: bgImage,
      // List Data
      listData: [],
      listDataMouPertahun: [],
      listDataMoaKategori: [],
      listDataMouTerbanyak: [],
      listDataMoaTerbanyak: [],
      // Chart MoU per Tahun
      chartsBar: {
        title: 'Data MoU Per Tahun',
        type: 'bar',
        bodyData: {
          chart: {
            height: 400,
            type: 'bar'
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          plotOptions: {
            bar: {
              distributed: true, // this line is mandatory
              horizontal: false
            }
          },
          dataLabels: {
            enabled: false
          },
          series: [
            {
              name: 'Jumlah MoU',
              data: [] // Untuk data chart
            }
          ],
          colors: [
            // this array contains different color code for each data
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
            '#69d2e7'
          ],
          yaxis: {
            labels: {
              // Untuk pengaturan lebar label y
              minWidth: 50,
              maxWidth: 100
            }
          },
          xaxis: {
            categories: [] // Untuk label chart
          }
        }
      },
      // Chart Kategori MoA
      chartsStackedBar: {
        title: 'Jenis Kerjasama Per Kategori',
        type: 'bar',
        bodyData: {
          chart: {
            type: 'bar'
          },
          title: {
            text: 'Kerjasama Ongoing dan Expired'
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Jumlah'
            }
          },
          legend: {
            reversed: true
          },
          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },
          series: [
            {
              name: 'Ongoing',
              data: [],
              color: '#007bff'
            },
            {
              name: 'Expired',
              data: [],
              color: '#ffc107'
            }
          ]
        }
      }
    }
  }
  // beforeRouteEnter (to, from, next) {
  //   const vuexIn = localStorage.getItem('vuex')
  //   const tokenIn = localStorage.getItem('access_token')
  //   if (!tokenIn && !vuexIn) {
  //     Vue.$toast.error('Sesi telah habis.')
  //   }
  //   return next()
  // }
}
</script>
<style>
@media only screen and (max-width: 769px) {
  .hide-content-769px {
    display: none;
  }
}
@media only screen and (max-width: 426px) {
  .hide-content-426px {
    display: none;
  }
}
.percentage.float-right {
  font-size: 20px;
}
.link-yt:hover {
  color: #ff0000;
}
</style>
